<template>
  <div class="faq">
    <AppText
      class="font-medium"
      variant="div"
      :opacity="0.4"
      type="uppercase"
    >
      {{ t('merchantWallet.faq.title') }}
    </AppText>

    <AppText
      size="15px"
      :line-height="1.35"
      class="font-medium"
      variant="div"
      mt="34px"
    >
      {{ t('merchantWallet.faq.question1.title') }}
    </AppText>

    <AppText
      :line-height="1.35"
      variant="div"
      mt="16px"
      class="border"
    >
      {{ t('merchantWallet.faq.question1.description') }}
    </AppText>

    <AppText
      size="15px"
      :line-height="1.35"
      class="font-medium"
      variant="div"
      mt="34px"
    >
      {{ t('merchantWallet.faq.question2.title') }}
    </AppText>

    <AppText
      :line-height="1.35"
      variant="div"
      mt="16px"
      class="border"
    >
      {{ t('merchantWallet.faq.question2.description') }}
    </AppText>

    <AppText
      size="15px"
      :line-height="1.35"
      class="font-medium"
      variant="div"
      mt="34px"
    >
      {{ t('merchantWallet.faq.question3.title') }}
    </AppText>

    <AppText
      :line-height="1.35"
      variant="div"
      mt="16px"
      class="border"
    >
      <i18n-t keypath="merchantWallet.faq.question3.description">
        <span class="cursor-pointer" style="color: var(--color-error);" @click="openModalToDisconnect">{{ t('merchantWallet.faq.question3.descriptionLink') }}</span>
      </i18n-t>
    </AppText>

    <ExternalLink
      :has-prepend="false"
      has-primary-color
      :href="link"
      style="margin-top: 20px; font-size: 13px;"
    >
      {{ t('merchantWallet.faq.learnMore') }}
    </ExternalLink>
  </div>
</template>

<script>
import ExternalLink from '@/components/ExternalLink.vue';

import { openModalToDisconnect } from '@/layouts/MainLayout/AppSidebar/useModalOpen';

export default {
  name: 'FAQ',
  components: {
    ExternalLink,
  },
  setup() {
    const link = `https://${process.env.VUE_APP_DOCS_URL}/home/merchant-guide/`;

    return { link, openModalToDisconnect };
  },
};
</script>

<style lang="scss" scoped>
.border {
  border-bottom: 1px solid var(--color-black-01);
  padding-bottom: 20px;
}

</style>
