import { ref } from 'vue';

export const isModalOpen = ref(false);

export const openModalToDisconnect = () => {
  isModalOpen.value = !isModalOpen.value;
};

export default {
  isModalOpen,
  openModalToDisconnect,
};
