<template>
  <div>
    <AppText
      :line-height="1.5"
      class="font-medium"
      variant="div"
      mb="8px"
    >
      {{ title }}
    </AppText>
    <slot />
  </div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    title: {
      type: String,
      default: '',
    },
  },
  setup() {
    return {};
  },
});
</script>
