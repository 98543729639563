<template>
  <div class="incognito-switcher" :class="{ disabled, hover: isInactiveElHover }">
    <FRadioButton
      v-slot="item"
      :model-value="isSwitcherActive"
      :options="options"
      :is-loading="isLoading"
      height="36px"
      :disabled="disabled"
      @change.stop="$emit('update')"
      @update-hover="updateHover"
    >
      <template v-if="item.value === true">
        <i v-if="isLoading && item.value === value" class="el-icon is-loading">
          <Loading />
        </i>
        <template v-else>
          <AppIcon
            name="incognito"
            size="18px"
            class="incognito-icon"
            :class="{ 'disabled-icon': !value }"
          />
          <AppText
            size="15px"
            :line-height="1.5"
            class="font-medium"
            type="uppercase"
            :class="{ 'green-text': item.value && !disabled }"
          >
            {{ t(`merchantWallet.publicAddress.label.${item.text}`) }}
          </AppText>
        </template>
      </template>

      <template v-if="item.value === false">
        <i v-if="isLoading && item.value === value" class="el-icon is-loading">
          <Loading />
        </i>
        <AppText
          v-else
          size="15px"
          :line-height="1.5"
          class="font-medium"
          type="uppercase"
          :class="{ 'green-text': item.value && !disabled }"
        >
          {{ t(`merchantWallet.publicAddress.label.${item.text}`) }}
        </AppText>
      </template>
    </FRadioButton>
  </div>
</template>

<script setup>
import { Loading } from '@element-plus/icons-vue';
import { ref } from 'vue';

defineProps({
  disabled: {
    type: Boolean,
    default: false,
  },
  isLoading: {
    type: Boolean,
    default: false,
  },
});

defineEmits(['update']);

const isSwitcherActive = defineModel({ type: Boolean, default: false });

const options = [
  { value: true, text: 'on' },
  { value: false, text: 'off' },
];

const isInactiveElHover = ref(false);

const updateHover = (val, modelValue, isHover) => {
  if (!isHover) {
    isInactiveElHover.value = false;
    return;
  }

  isInactiveElHover.value = (val === false && modelValue === true) || (val === true && modelValue === false);
};
</script>

<style lang="scss" scoped>
.incognito-switcher {
  &.hover {
    :deep(.el-radio-group) {
      background: var(--color-DEDEDE) !important;
    }
  }

  :deep(.f-radio-button) {
    display: flex;
    justify-content: center;

    .el-radio-group {
      background: var(--color-EBEBEB);
      border-radius: 8px;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      @include transition-base('color');

      .el-radio-button__inner {
        margin: 0;
        height: 48px;
        width: 89px;
        background: none;
        border-radius: 8px;
        justify-content: center;
        font-size: 14px;
        line-height: 1.2;
        padding: 0 13px;
        border: 1px solid transparent;
        @include font-medium;

        .icon path {
          fill: var(--color-black-06);
        }

        .text {
          color: var(--color-black-06);
          @include transition-base('color');
        }

        &:hover {
          background: transparent;

          .icon path {
            fill: var(--color-black);
          }

          .text {
            color: var(--color-black);
          }
        }
      }
    }

    .is-selected {
      .el-radio-button__inner {
        background: var(--color-white) !important;
        border-radius: 7px;
        border: 1px solid var(--color-black-01) !important;

        @include transition-base;

        .icon path {
          fill: var(--color-green);
        }

        .text {
          color: var(--color-black);
          @include transition-base;
        }

        .text.green-text {
          color: var(--color-green);
        }

        &:hover {
          .icon path {
            fill: var(--color-green);
          }
        }
      }
    }
  }

  &.disabled {
    :deep(.f-radio-button) {
      cursor: not-allowed;

      .el-radio-group {
        background: #EBEBEB !important;
        pointer-events: none;
      }
      .el-radio-button__inner {
        background: #EBEBEB !important;
        .text {
          color: var(--color-black-02) !important;
        }
        .icon path {
          fill: #BCBCBC;
        }
      }
      .is-selected {
        .el-radio-button__inner {
          background: #E0E0E0 !important;
          border: 1px solid #E0E0E0 !important;
        }
      }
    }
  }

  .incognito-icon {
    margin-right: 10px;
    :deep(path) {
      fill: var(--color-green);
    }

    &.disabled-icon {
      :deep(path) {
        fill: var(--color-black);
      }
    }
  }
}
</style>
