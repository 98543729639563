<template>
  <AppText
    variant="div"
    size="20px"
    class="font-medium"
  >
    {{ t('merchantWallet.coins.title') }}
  </AppText>
  <AppText
    variant="div"
    :line-height="1.5"
    mt="12px"
    mb="10px"
  >
    {{ t('merchantWallet.coins.description') }}
  </AppText>

  <template v-for="coin in primaryCoins" :key="coin.name">
    <AvailableCoin
      v-model:is-processing="isProcessing"
      :coin="coin"
      :is-data-loading="isLoading"
      :can-be-cancelled="canBeCancelled"
    />
  </template>
  <div v-if="thirdPartyCoins?.length" class="d-flex align-items-center" style="padding: 20px 0 12px;">
    <div class="position-relative" style="margin-right: 12px;">
      <AppIcon
        v-show="!someTokensEnabled || areTokensDisabledByAdmin"
        name="coins-group-disabled"
        is-img-tag
      />
      <AppIcon
        v-show="!(!someTokensEnabled || areTokensDisabledByAdmin)"
        name="coins-group-active"
        is-img-tag
      />
    </div>
    <AppText
      size="15px"
      class="font-medium"
      :color="!someTokensEnabled || areTokensDisabledByAdmin ? 'var(--color-black-04)' : 'var(--color-black)'"
    >
      {{ t('common.thirdPartyTokens') }}
    </AppText>

    <FSpacer />

    <FSwitch
      :model-value="someTokensEnabled"
      :is-intermidiate="someTokensEnabled && someTokensDisabled"
      :loading="isBulkLoading"
      :disabled="areTokensDisabledByAdmin"
      is-small
      style="height: 20px;"
      @update:model-value="onBulkChange"
    />
  </div>
  <template v-for="coin in thirdPartyCoins" :key="coin.name">
    <AvailableCoin
      v-model:is-processing="isProcessing"
      :coin="coin"
      :is-data-loading="isLoading"
      :can-be-cancelled="canBeCancelled"
      is-third-party-coin
    />
  </template>

  <FSpacer v-if="thirdPartyCoins?.length" style="border-top: 1px solid var(--color-black-01); margin-top: 12px;" />

  <AppText color="var(--color-black-04)" variant="div" mt="16px">
    <i18n-t :keypath="walletType === 'GENERATED' ? 'merchantWallet.coins.label.generated' : 'merchantWallet.coins.label.imported'">
      <template #date>
        <DateFormatter :date="walletCreationDate" />
      </template>
      <template #time>
        <DateFormatter :date="walletCreationDate" :format="timeFormat" />
      </template>
    </i18n-t>
  </AppText>
</template>

<script setup>
import { computed, ref } from 'vue';

import DateFormatter from '@/components/Helpers/DateFormatter.vue';

import { useSettings } from '@/composables/useSettings';
import { useWallet } from '@/composables/useWallet';
import { useNotifications } from '@/composables/useNotifications';
import { thirdPartyTokensBulkUpdate } from '@/api/merchantWallet';
import { i18n } from '@/plugins/localization';

import AvailableCoin from './AvailableCoin.vue';

const props = defineProps({
  coins: {
    type: Array,
    default: () => [],
  },
  isLoading: {
    type: Boolean,
    default: false,
  },
});

const canBeCancelled = computed(() => props.coins.filter((coin) => coin.enabled && !coin.isDisabledByAdmin).length > 1);
const canGroupBeCancelled = computed(() => props.coins.filter((coin) => coin.enabled && !coin.isDisabledByAdmin && coin.isPrimary).length >= 1);

const primaryCoins = computed(() => props.coins.filter((item) => item.isPrimary));
const thirdPartyCoins = computed(() => props.coins.filter((item) => !item.isPrimary));
const someTokensEnabled = computed(() => thirdPartyCoins.value.some((item) => item.enabled));
const someTokensDisabled = computed(() => thirdPartyCoins.value.some((item) => !item.enabled));
const areTokensDisabledByAdmin = computed(() => thirdPartyCoins.value.every((item) => item.isDisabledByAdmin));

const canBeThirdPartyCancelled = computed(() => primaryCoins.value.filter((coin) => coin.enabled).length >= 1);

const { timeFormat } = useSettings();

const isProcessing = ref(false);

const { walletType, walletCreationDate } = useWallet();

const { addNotification } = useNotifications();
const { t } = i18n.global;
const isBulkLoading = ref(false);
const onBulkChange = async () => {
  if (!isProcessing.value) {
    isBulkLoading.value = true;
    isProcessing.value = true;

    if (canBeThirdPartyCancelled.value && canGroupBeCancelled.value) {
      await thirdPartyTokensBulkUpdate(!someTokensEnabled.value);
    } else {
      await new Promise((resolve) => { setTimeout(resolve, 200); });
      addNotification({ text: t('merchantWallet.notification.atLeastOneCoinWarning'), config: { color: 'warning' } });
    }
    isBulkLoading.value = false;
    isProcessing.value = false;
  }
};
</script>
