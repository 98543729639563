<template>
  <AppTooltip>
    <template #text>
      {{ isActive ? t('common.disable') : t('common.enable') }}
    </template>
    <div
      tabindex="0"
      class="pa-button"
      @mouseenter="isAnimateHover = true"
      @mouseleave="isAnimateHover = false"
      @click.stop="onAction"
      @keypress.space="onAction"
    >
      <div v-if="isActive" class="badge">
        <AppIcon class="badge-icon" name="power" :opacity="0.6" size="18px" />
      </div>

      <AppIcon
        v-if="!isActive"
        class="power-icon"
        name="power"
        size="24px"
      />

      <div v-else class="animation">
        <div class="goal">
          <LottiePlayer v-if="isIncognito" :data="IncognitoAnimation" />
          <LottiePlayer v-else :data="GoalAnimation" />
        </div>
        <div class="goal-hover">
          <LottiePlayer v-if="isIncognito" :data="IncognitoAnimationHover" />
          <LottiePlayer v-else :data="GoalAnimationHover" />
        </div>
      </div>
    </div>
  </AppTooltip>
</template>

<script setup>
import { ref } from 'vue';

import LottiePlayer from '@/components/LottiePlayer.vue';
import AppTooltip from '@/components/AppTooltip.vue';

import IncognitoAnimation from '@/assets/animations/incog.json';
import IncognitoAnimationHover from '@/assets/animations/incog-hover.json';
import GoalAnimation from '@/assets/animations/goal.json';
import GoalAnimationHover from '@/assets/animations/goal-hover.json';

defineProps({
  isActive: {
    type: Boolean,
    default: false,
  },
  isIncognito: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['action']);

const isAnimateHover = ref(false);

const onAction = (e) => {
  e.preventDefault();
  emit('action');
};
</script>

<style lang="scss" scoped>
.pa-button {
  cursor: pointer;
  width: 60px;
  height: 60px;
  background: var(--color-white);
  box-shadow: 0 2px 5px var(--color-black-01);
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  &:focus-visible {
    @include focus-visible-style;
    border-radius: 20px !important;
  }

  &:hover {
    .badge {
      @include transition-base('background-color');
      .badge-icon {
        fill: var(--color-error);
        @include transition-base('fill');
      }
    }

    .power-icon {
      fill: var(--color-green);
    }

    .animation .goal {
      opacity: 0;
    }

    .animation .goal-hover {
      opacity: 1;
    }
  }

  .badge {
    position: absolute;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    top: -10px;
    right: -10px;
    background: var(--color-white);
    border: 1px solid #EFEFEF;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
  }

  .animation {
    width: 60px;
    height: 60px;
    overflow: hidden;
    border-radius: 50%;
    position: relative;

    & > div {
      position: absolute;
      top: 0;
      left: 0;
    }

    .goal {
      opacity: 1;
      @include transition-base('opacity');
    }

    .goal-hover {
      opacity: 0;
      @include transition-base('opacity');
    }
  }
}
</style>
