<template>
  <ActionForm
    :request-fields="requestFields"
    :validation-schema="validationSchema"
    :response-errors="fieldsErrors"
    style="position: relative"
    @validate="onPassedValidation"
    @error="errors => fieldsErrors = errors"
  >
    <template #default="{ errors, action }">
      <div v-click-outside="onClickOutside">
        <FInput
          ref="input"
          v-model="requestFields.minAmount"
          class="min-amount"
          :class="{ loading: isLoading, focused: isFocused, disabled }"
          :mask="mask"
          maxlength="6"
          :disabled="disabled"
          no-margin
          size="medium"
          @focus="onFocus"
        >
          <template #prefix>
            <AppText
              size="20px"
              :line-height="1.5"
              variant="div"
              color="var(--color-black)"
              :opacity="signOpacity"
            >
              {{ sign }}
            </AppText>
          </template>

          <template v-if="!disabled && isFocused" #append>
            <FButton
              size="x-small"
              type="inner"
              class="save-button"
              :disabled="disabled"
              @click="onSaveClick(action)"
            >
              <span style="text-transform: uppercase;" class="font-medium">{{ t('merchantWallet.publicAddress.button.save') }}</span>
            </FButton>
          </template>
        </FInput>
      </div>
      <div style="position:absolute; top: 52px;">
        <AppText color="var(--color-error)" style="height: 25px;">
          {{ errors.minAmount?.message }}
        </AppText>
      </div>
    </template>
  </ActionForm>
</template>

<script setup>
import { computed, ref, watch } from 'vue';
import { storeToRefs } from 'pinia';

import { clickOutside as vClickOutside } from '@/directives/clickOutside';
import { currencyList } from '@/common/data';
import { currency } from '@/composables/useCurrency';
import { ActionForm, minAmountRule, fieldValidation } from '@/validation';
import {
  addThousandsSeparators,
  getMask,
  parseSeparatedNumber,
} from '@/utils/numberFormatter';
import { useStore } from '@/store';

const emit = defineEmits(['update:modelValue', 'change']);

const props = defineProps({
  modelValue: {
    type: [Number, String],
    default: 0,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  isLoading: {
    type: Boolean,
    default: false,
  },
  numberFormat: {
    type: String,
    default: 'comma',
  },
});

const store = useStore();

const { currencyValue } = storeToRefs(store);

const sign = computed(() => currency.value.sign);
const signOpacity = computed(() => (props.disabled ? '0.2' : '1'));

const minimumAmount = computed(() => (currencyList.find((item) => item.short === currencyValue.value).minAmount));

const {
  validationSchema,
  requestFields,
  fieldsErrors,
} = fieldValidation({
  fieldName: 'minAmount',
  rule: minAmountRule(
    minimumAmount.value,
    props.numberFormat,
    currency.value.value,
  ),
});

const input = ref(null);

watch(computed(() => props.modelValue), () => {
  requestFields.minAmount = addThousandsSeparators({ val: parseSeparatedNumber(props.modelValue, props.numberFormat), separator: props.numberFormat });
}, { immediate: true });

const onPassedValidation = () => {
  const newValue = Number(requestFields.minAmount.replaceAll(/[,.]/g, ''));
  if (newValue !== props.modelValue) {
    emit('change', requestFields.minAmount, fieldsErrors);
  }
};

const isSavedButtonVisible = computed(() => {
  const notEqualValues = Number(parseSeparatedNumber(requestFields.minAmount, props.numberFormat)) !== Number(parseSeparatedNumber(props.modelValue, props.numberFormat));
  const moreThanMinAmount = Number(parseSeparatedNumber(requestFields.minAmount, props.numberFormat)) >= Number(parseSeparatedNumber(minimumAmount.value, props.numberFormat));

  return notEqualValues && moreThanMinAmount;
});

const isFocused = ref(false);
const onClickOutside = (triggerEvent) => {
  const hasValidationErrors = fieldsErrors.value?.minAmount?.length && triggerEvent.type !== 'mousedown';
  const isMouse = triggerEvent.type === 'mousedown';

  if (isSavedButtonVisible.value || !hasValidationErrors) {
    requestFields.minAmount = addThousandsSeparators({ val: props.modelValue, separator: props.numberFormat });
  }

  if (isMouse || (isSavedButtonVisible.value && !hasValidationErrors)) {
    isFocused.value = false;
  }
};

const onSaveClick = (action) => {
  action();
  input.value.$el.querySelector('.el-input__inner').focus();
};

const onFocus = () => {
  isFocused.value = true;
};

watch(computed(() => props.isLoading), (isLoadingValue) => {
  if (isLoadingValue === false) {
    isFocused.value = false;
    input.value.$el.querySelector('.el-input__inner').blur();
  }
});

const mask = computed(() => getMask(currency.value.value, props.numberFormat));
</script>

<style lang="scss" scoped>
.min-amount {
  opacity: 1;
  @include transition-base(opacity);

  &.loading {
    opacity: 0.5;
  }

  &.disabled {
    :deep(.el-input__wrapper) {
      background: #EDEDED !important;
      cursor: not-allowed !important;
    }
  }

  &:not(.focused) {
    :deep(.el-input__wrapper) {
      background: transparent;
    }
  }

  :deep(.el-input__wrapper) {
    border-radius: 8px !important;

    .el-input__prefix {
      margin-right: 0;
      .el-input__prefix-inner {
        @include font-medium;
      }
    }

    .el-input__inner {
      padding-left: 0 !important;
      font-size: 20px;
      line-height: 1.5;
      width: 190px;
      caret-color: var(--color-primary);
      @include font-medium;
    }
  }

  :deep(.is-disabled) {
    .el-input__wrapper {
      background-color: #EBEBEB;
      border: 1px solid var(--color-black-01);
      color: rgba(0, 0, 0, 0.2);
      cursor: not-allowed;

      &:hover {
        border-color: var(--color-black-01);
      }
    }
  }
  :deep(.slot-append) {
    right: 8px;
  }
}

.save-button {
  width: 58px;
  height: 32px;
  border-radius: 8px;

  &:hover {
    background-color: var(--color-primary-01);
  }
}
</style>
