<template>
  <AppTooltip
    :is-enabled="coin.isDisabledByAdmin"
    text-align="left"
    is-block
    cursor="default"
  >
    <template #text>
      {{ t('merchantWallet.coins.label.disabled') }}
    </template>

    <div class="wrapper">
      <div
        class="available-coin"
        :class="{
          'disabled-globally': coin.isDisabledByAdmin,
          'is-third-party-coin': isThirdPartyCoin,
        }"
      >
        <div class="position-relative">
          <FSkeleton
            variant="circle"
            :is-loading="isDataLoading"
            circle-size="32px"
            transition-name="fade-absolute-1"
          >
            <div class="position-relative">
              <AppIcon
                v-show="isEnabled"
                :key="coin.isDisabledByAdmin"
                :opacity="coin.isDisabledByAdmin ? '0.2' : '1'"
                :name="coin.icon"
                :size="isThirdPartyCoin ? '24px' : '32px'"
                is-img-tag
              />
              <AppIcon
                v-show="!isEnabled"
                :name="coin.iconDisabled"
                :size="isThirdPartyCoin ? '24px' : '32px'"
                is-img-tag
              />
            </div>
          </FSkeleton>
        </div>
        <div
          class="flex-grow-1 position-relative"
          :class="{
            'd-flex align-items-center': coin.name === 'ethereum',
          }"
        >
          <FSkeleton
            :is-loading="isDataLoading"
            transition-name="fade-absolute-1"
            height="20px"
            style="width: 70px;"
          >
            <div class="position-relative">
              <AppText
                :size="isThirdPartyCoin ? '13px' : '15px'"
                :line-height="1.35"
                class="font-medium"
                :opacity="!isEnabled || coin.isDisabledByAdmin ? 0.4 : 1"
              >
                {{ coin.name }}
              </AppText>
            </div>
          </FSkeleton>
        </div>

        <FSkeleton :is-loading="isDataLoading" height="16px" style="min-width: 120px;" transition-name="fade-absolute-1">
          <div class="position-relative d-flex justify-content-end status-wrapper">
            <AppText
              v-if="coin.isDisabledByAdmin"
              variant="div"
              :line-height="1.5"
              class="font-medium disabled-status"
            >
              {{ t('common.unavailable') }}
            </AppText>

            <AppText
              v-if="!isThirdPartyCoin && !coin.isDisabledByAdmin"
              variant="div"
              color="var(--color-black-04)"
              :line-height="1.5"
            >
              {{ statusText }}
            </AppText>
            <template v-if="isThirdPartyCoin">
              <AppIcon :name="`${coin.familyIcon}-flat`" size="18px" is-img-tag />
              <AppText variant="div" color="var(--color-black-04)" :line-height="1.5" style="width: 48px">
                {{ coin.family }}
              </AppText>
            </template>
            <FSwitch
              :model-value="isEnabled"
              :loading="isLoading"
              :disabled="coin.isDisabledByAdmin"
              is-small
              style="height: 20px;"
              @update:model-value="onToggleCoin"
            />
          </div>
        </FSkeleton>
      </div>
      <slot name="append" />
    </div>
  </AppTooltip>
</template>

<script setup>
import { computed, ref, watch } from 'vue';

import AppTooltip from '@/components/AppTooltip.vue';

import { useNotifications } from '@/composables/useNotifications';
import { i18n } from '@/plugins/localization';
import { coinStatusUpdate } from '@/api/merchantWallet';

const props = defineProps({
  isDataLoading: {
    type: Boolean,
    default: false,
  },
  coin: {
    type: Object,
    default: () => ({}),
  },
  canBeCancelled: {
    type: Boolean,
    default: false,
  },
  isThirdPartyCoin: {
    type: Boolean,
    default: false,
  },
});

const { t } = i18n.global;
const isProcessing = defineModel('isProcessing', { type: Boolean, defaultValue: false });

const isLoading = ref(false);
const isEnabled = ref(false);
const { addNotification } = useNotifications();

const onToggleCoin = async () => {
  if (!isProcessing.value) {
    isLoading.value = true;
    isProcessing.value = true;
    if (props.canBeCancelled || (!props.canBeCancelled && !props.coin.enabled)) {
      await coinStatusUpdate(props.coin.value);
    } else {
      await new Promise((resolve) => { setTimeout(resolve, 200); });
      addNotification({ text: t('merchantWallet.notification.atLeastOneCoinWarning'), config: { color: 'warning' } });
    }
    isLoading.value = false;
    isProcessing.value = false;
  }
};

const statusText = computed(() => (isEnabled.value ? t('common.active') : t('common.disabled')));

watch(computed(() => props.coin.isDisabledByAdmin), () => {
  if (!props.coin.enabled && props.coin.isDisabledByAdmin) {
    isEnabled.value = false;
  }
});

watch(computed(() => props.coin.enabled), () => {
  isEnabled.value = props.coin.enabled;
}, { immediate: true });
</script>

<style lang="scss" scoped>
.wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.available-coin {
  width: 100%;
  min-height: 68px;
  display: flex;
  align-items: center;
  gap: 20px;
  border-bottom: 1px solid var(--color-black-01);

  .status-wrapper {
    display: flex;
    gap: 16px;
    position: relative;
    align-items: center;
  }

  &.disabled-globally {
    .status-wrapper {
      align-items: center;
      top: 0;
    }

    .disabled-status {
      color: #784A20;
      padding: 4px 13px;
      background: #FCEBCC;
      border-radius: 4px;
      opacity: 1;
      top: 0px;
    }
  }

  &.is-third-party-coin {
    min-height: 40px;
    gap: 16px;
    border-bottom: unset;
  }
}
</style>
