<template>
  <AppTooltip
    class="pa-link"
    tabindex="0"
    :class="{ disabled }"
    :is-enabled="!isCopyingProcess"
    @click="copyToClipboard"
    @keypress.space="onSpacePress"
  >
    <template #text>
      {{ t('common.copy') }}
    </template>

    <transition name="fade-absolute">
      <div v-if="isCopyingProcess" class="tip">
        <AppIcon
          name="arrow-check-done"
          size="18px"
          fill="var(--color-primary)"
        />
        <AppText
          size="17px"
          color="var(--color-primary)"
          variant="div"
          ml="12px"
        >
          {{ t('common.copied') }}
        </AppText>
      </div>
    </transition>

    <AppIcon name="globe-world" :opacity="0.4" size="18px" is-img-tag />

    <AppText
      size="17px"
      variant="div"
      ml="4px"
      class="url-link"
      style="padding: 4px 8px 4px 8px;"
    >
      {{ url }}
    </AppText>

    <FSpacer />

    <AppIcon name="copy" size="18px" :opacity="0.4" />
  </AppTooltip>
</template>

<script>
import { ref } from 'vue';

import AppTooltip from '@/components/AppTooltip.vue';

import { copyTextToClipboard } from '@/utils/functions';

export default {
  name: 'PublicAddressLink',
  components: {
    AppTooltip,
  },
  props: {
    url: {
      type: String,
      default: '',
    },
    urlToCopy: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const isCopyingProcess = ref(false);
    const copyToClipboard = () => {
      if (!isCopyingProcess.value) {
        isCopyingProcess.value = true;
        copyTextToClipboard(props.urlToCopy);

        setTimeout(() => {
          isCopyingProcess.value = false;
        }, 1200);
      }
    };

    const onSpacePress = (e) => {
      e.preventDefault();
      copyToClipboard();
    };

    return { copyToClipboard, isCopyingProcess, onSpacePress };
  },
};
</script>

<style lang="scss" scoped>
.pa-link {
  width: 400px;
  height: 60px;
  border: 1px solid var(--color-primary);
  box-shadow: 0px 2px 5px var(--color-black-01);
  border-radius: 4px;
  position: relative;
  overflow: hidden;
  padding: 0 20px;
  display: flex;
  align-items: center;
  background-color: var(--color-white);
  &:focus-visible {
    @include focus-visible-style;
    border-color: transparent;
  }

  &.disabled {
    background: #EDEDED;
    border: 1px solid var(--color-black-01);
    box-shadow: none;
  }

  .tip {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
    background: #FBF3EC;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
  }
}

.url-link {
  display: inline-block;
  @include focus-visible;
}
</style>
