<template>
  <div class="public-address" :class="{ 'is-loading': isBaseLoading }">
    <div class="header">
      <PublicAddressButton :is-active="isEnabled" :is-incognito="incognito" @action="onWalletEnabledUpdate" />
      <div>
        <AppText variant="div" size="20px" class="font-medium">
          {{ isEnabled ? t('merchantWallet.publicAddress.label.enabled') : t('merchantWallet.publicAddress.label.disabled') }}
        </AppText>
        <AppText variant="div" :line-height="1.5" mt="8px">
          {{ t('merchantWallet.publicAddress.label.description') }}
        </AppText>
      </div>
    </div>

    <div class="body">
      <SettingWrapper :title="t('merchantWallet.publicAddress.label.yourAddress')">
        <PublicAddressLink :url="publicLink" :url-to-copy="urlToCopy" :disabled="!isEnabled" />
      </SettingWrapper>

      <SettingWrapper :title="t('merchantWallet.publicAddress.label.minAmount')">
        <MinimumAmountInput
          :model-value="amount"
          :is-loading="isAmountLoading"
          :disabled="!isEnabled"
          :number-format="numberFormat"
          @change="onAmountUpdate"
        />
      </SettingWrapper>

      <SettingWrapper :title="t('merchantWallet.publicAddress.label.incognito')">
        <IncognitoSwitcher
          v-model="incognito"
          :is-loading="isIncognitoLoading"
          :disabled="!isEnabled"
          @update="onIncognitoUpdate"
        />
      </SettingWrapper>
    </div>
  </div>
</template>

<script setup>
import { computed, ref, watch } from 'vue';

import { emitter } from '@/composables/useBus';
import { useUser } from '@/composables/useUser';
import { useSettings } from '@/composables/useSettings';
import { parseSeparatedNumber } from '@/utils/numberFormatter';
import { updatePublicPayments } from '@/api/merchantWallet';
import { useApp } from '@/composables/useApp';

import IncognitoSwitcher from './IncognitoSwitcher.vue';
import MinimumAmountInput from './MinimumAmountInput.vue';
import PublicAddressButton from './PublicAddressButton.vue';
import PublicAddressLink from './PublicAddressLink.vue';
import SettingWrapper from './SettingWrapper.vue';

const props = defineProps({
  publicPayments: {
    type: Object,
    default: () => {},
  },
});

const isEnabled = ref(true);
const amount = ref(5);
const incognito = ref(true);

const isBaseLoading = ref(false);
const isIncognitoLoading = ref(false);
const isAmountLoading = ref(false);

const { numberFormat } = useSettings();

watch(computed(() => props.publicPayments), () => {
  isEnabled.value = props.publicPayments?.isEnabled;
  amount.value = props.publicPayments?.amount;
  incognito.value = props.publicPayments?.incognito;

  isBaseLoading.value = false;
  isIncognitoLoading.value = false;
  isAmountLoading.value = false;
}, { immediate: true, deep: true });

const onWalletEnabledUpdate = async () => {
  isBaseLoading.value = true;
  await updatePublicPayments({
    isEnabled: !isEnabled.value,
    amount: amount.value,
    incognito: incognito.value,
  });

  emitter.emit('update-wallet-page', () => {});
};

const onAmountUpdate = async (amountValue) => {
  isAmountLoading.value = true;
  amount.value = amountValue;
  const { isSuccess } = await updatePublicPayments({
    isEnabled: isEnabled.value,
    amount: parseSeparatedNumber(amountValue, numberFormat.value),
    incognito: incognito.value,
  });

  if (isSuccess) {
    emitter.emit('update-wallet-page');
  }
  isAmountLoading.value = false;
};

const onIncognitoUpdate = async () => {
  isIncognitoLoading.value = true;
  await updatePublicPayments({
    isEnabled: isEnabled.value,
    amount: amount.value,
    incognito: !incognito.value,
  });

  emitter.emit('update-wallet-page');
};

const { uid } = useUser();
const { IS_ONION, BASE_URL, truncatedShortOnionUrl } = useApp();

const publicLinkEnd = computed(() => `pay/${uid.value}`);
const publicLink = computed(() => {
  const URL = IS_ONION ? truncatedShortOnionUrl : BASE_URL;
  return `${URL}/${publicLinkEnd.value}`;
});

const urlToCopy = computed(() => {
  if (IS_ONION) {
    return `http://${BASE_URL}/${publicLinkEnd.value}`;
  }
  return `https://${BASE_URL}/${publicLinkEnd.value}`;
});
</script>

<style lang="scss" scoped>
.public-address {
  width: 100%;
  background-color: #F7F7F7;
  padding: 34px 40px 48px 40px;
  border-bottom: 1px solid var(--color-black-01);
  opacity: 1;

  @include transition-base(opacity);

  &.is-loading {
    opacity: 0.4;
  }

  .header {
    display: flex;
    gap: 30px;
  }

  .body {
    margin-top: 24px;
    display: flex;
    flex-wrap: wrap;
    gap: 40px;
    align-items: center;
  }
}
</style>
